//import "../node_modules/jquery/dist/jquery.min.js";
// import "../node_modules/popper/dist/popper.min.js";
// import "../node_modules/bootstrap/js/bootstrap.min.js";
// import "../node_modules/aos/dist/aos.js";
import "../js/theme/js/custom.js";
import "../js/theme/js/jquery.touchSwipe.min.js";
import "../js/theme/js/jquery.waypoints.min.js";
import "../js/theme/js/jquery.counterup.min.js";
import "../node_modules/bootstrap-touch-slider/bootstrap-touch-slider.js";
import "../node_modules/owl.carousel/dist/owl.carousel.min.js";
 
  $('.op-clo').on('click', function() {
        $('body .h7-nav-box').toggleClass("show");
    });
    // This is for slider     
    $('#slider1').bsTouchSlider();
    $(".carousel .carousel-inner").swipe({
        swipeLeft: function(event, direction, distance, duration, fingerCount) {
            this.parent().carousel('next');
        },
        swipeRight: function() {
            this.parent().carousel('prev');
        },
        threshold: 0
    });
    // This is for counter
    $('.counter').counterUp({
        delay: 10
    });
    // This is for the testimonial
    $('.testi9').owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        dots: true,
        autoplay: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1

            },
            1650: {
                items: 1
            }
        }
    });